import "./styles.css";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { AnimatedCounter } from "react-animated-counter";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { ToggleButtonGroup, ToggleButton } from "@mui/material";

function convertTo24HourTime(unixTimestamp) {
  const date = new Date(parseInt(unixTimestamp, 10));
  const hours = date.getUTCHours() + 1;
  const formattedHours = (hours % 24).toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${formattedHours}:${minutes}`;
}

const normalizeValue = (value) => Math.max(0, value);

const App = () => {
  const [occupancy, setOccupancy] = useState({ "Edward Boyle": 0, "Laidlaw": 0 });
  const [historicalData, setHistoricalData] = useState([]);
  const [alignment, setAlignment] = useState("Today");
  const [cooldown, setCooldown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Memoize data parsing to avoid unnecessary processing
  const parsedData = useMemo(() => {
    return historicalData.map(item => ({
      time: convertTo24HourTime(item.time),
      EdwardBoyle: normalizeValue(item.EdwardBoyle),
      Laidlaw: normalizeValue(item.Laidlaw),
    }));
  }, [historicalData]);

  // Handle toggle button group changes with cooldown
  const handleAlignment = useCallback((event, newAlignment) => {
    if (cooldown || newAlignment === null) return;

    setAlignment(newAlignment);
    setCooldown(true);

    setTimeout(() => {
      setCooldown(false);
    }, 5000);
  }, [cooldown]);

  // Memoized fetch function for better reusability
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://libapi.joshm.tech/${alignment.toLowerCase()}`);
      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();
      setHistoricalData(data);
      
      // Extract current occupancy from the latest data point
      if (data.length > 0) {
        const latestData = data[data.length - 1];
        setOccupancy({
          "Edward Boyle": normalizeValue(latestData.EdwardBoyle),
          "Laidlaw": normalizeValue(latestData.Laidlaw)
        });
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [alignment]);

  // Initial data fetch and periodic updates
  useEffect(() => {
    fetchData();

    const now = new Date();
    const delay = (60 - now.getSeconds()) * 1000;

    const initialTimeoutId = setTimeout(() => {
      fetchData();
      const intervalId = setInterval(fetchData, 60 * 1000);
      return () => clearInterval(intervalId);
    }, delay);

    return () => clearTimeout(initialTimeoutId);
  }, [fetchData]);

  // Custom tooltip component using useMemo for efficiency
  const CustomTooltip = useMemo(() => {
    return ({ payload, label }) => (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        <div className="occupancy-row">
          <p className="inline-label" style={{ color: "#82ca9d" }}>
            Edward Boyle:
          </p>
          <div className="occupancy-container">
            <AnimatedCounter
              fontSize="18px"
              value={payload[0]?.value}
              decimalPrecision={0}
            />
          </div>
        </div>
        <div className="occupancy-row">
          <p className="inline-label" style={{ color: "#8884d8" }}>
            Laidlaw:
          </p>
          <div className="occupancy-container">
            <AnimatedCounter
              fontSize="18px"
              value={payload[1]?.value}
              decimalPrecision={0}
            />
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="App">
      <h1>Library Occupancy Statistics</h1>
      <div className="counter-container">
        <div className="counter-wrapper">
          <div className="counter-label">Edward Boyle</div>
          <AnimatedCounter
            decimalPrecision={0}
            value={occupancy["Edward Boyle"]}
            color="white"
            fontSize="40px"
          />
          <a href="https://library.leeds.ac.uk/locations/libraries/edward-boyle" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>Capacity: 1800</a>
        </div>
        <div className="counter-wrapper" style={{ padding: "10px 7px" }}>
          <div className="counter-label">Laidlaw</div>
          <AnimatedCounter
            decimalPrecision={0}
            value={occupancy["Laidlaw"]}
            color="white"
            fontSize="40px"
          />
          <a href="https://library.leeds.ac.uk/locations/libraries/laidlaw" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>Capacity: 720</a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "65px",
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="time"
          >
            <ToggleButton
              sx={{ color: cooldown ? "grey" : "white" }}
              value="Today"
            >
              Today
            </ToggleButton>
            <ToggleButton
              sx={{ color: cooldown ? "grey" : "white" }}
              value="Yesterday"
            >
              Yesterday
            </ToggleButton>
            <ToggleButton
              sx={{ color: cooldown ? "grey" : "white" }}
              value="Week"
            >
              Last Week
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {isLoading ? (
          <div style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>
            Loading data...
          </div>
        ) : (
          <ResponsiveContainer height={300} width="95%">
            <LineChart width="100%" data={parsedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" tick={{ fill: "white" }} />
              <YAxis tick={{ fill: "white" }} domain={["auto", "auto"]} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line
                type="monotone"
                dataKey="EdwardBoyle"
                stroke="#82ca9d"
                name="Edward Boyle"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="Laidlaw"
                stroke="#8884d8"
                name="Laidlaw"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default App;
